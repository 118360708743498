import './Login.css';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authenticateRoot, authenticateTaxi } from '../../redux/slices/passwordSlice';

function Login({ page }) {
  const dispatch = useDispatch();
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    if (page === 'root' && password === 'WarpDriveForever@739') {
      dispatch(authenticateRoot());
    } else if (page === 'taxi' && password === 'Lermolas739') {
      dispatch(authenticateTaxi());
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <div>
      <h2>Login to {page === 'root' ? 'Home' : 'Taxi Work'}</h2>
      <input 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        placeholder="Enter password"
      />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
}

export default Login;