import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Timer from 'easytimer.js';
import { toggleTimer, resetTimer, updateTimeLeft, switchMode, addCycle, resetCycles, setTimerConfig } from '../../redux/slices/timerSlice'
import './Pomodoro.css'
import resetImage from '../../assets/reset.png'
import nextImage from '../../assets/next.png'
import woodAlarm from '../../assets/alarm-wood.mp3'

function Pomodoro() {
    const dispatch = useDispatch();
    const timer = useRef(new Timer());
    const { isActive, timeLeft, mode, cycles, timerConfig } = useSelector(state => state.timer);

    // Configure and start the timer
    useEffect(() => {
        timer.current.removeAllEventListeners()
        // Configure the timer to count down
        timer.current.addEventListener('secondsUpdated', () => {
            const totalSeconds = timer.current.getTotalTimeValues().seconds;
            dispatch(updateTimeLeft(totalSeconds));
        });

        timer.current.addEventListener('targetAchieved', () => {
            dispatch(toggleTimer(false));
            
            let newMode, newTimeLeft, title;
            if (timerConfig === '25/5/15') {
              if (mode === 'work') {
                if (cycles === 3) {
                  dispatch(resetCycles());
                  newMode = 'longBreak';
                  newTimeLeft = 900;
                  title = 'Time to take a long break!';
                } else {
                    dispatch(addCycle());
                    newMode = 'shortBreak';
                    newTimeLeft = 300; // 5 minutes for short break
                    title = 'Time to take a short break!';
                }
              } else if (mode === 'shortBreak' || mode === 'longBreak') {
                  newMode = 'work';
                  newTimeLeft = 1500;
                  title = 'Time to get back to work!';
              }
            } else if (timerConfig === '50/10/30') {
                if (mode === 'work') {
                  if (cycles === 3) {
                    dispatch(resetCycles());
                    newMode = 'longBreak';
                    newTimeLeft = 1800; // 30 minutes for long break
                    title = 'Time to take a long break!';
                  } else {
                    dispatch(addCycle());
                    newMode = 'shortBreak';
                    newTimeLeft = 600; // 10 minutes for short break
                    title = 'Time to take a short break!';
                  }
                } else if (mode === 'shortBreak' || mode === 'longBreak') {
                  newMode = 'work';
                  newTimeLeft = 3000; // 50 minutes for work
                  title = 'Time to get back to work!';
                }
              }

            if (Notification.permission === 'granted') {
              const notification = new Notification(title, {body: ''});
              new Audio(woodAlarm).play();
            } else {
              console.log('Notification permission not granted.');
            }

            dispatch(switchMode({ mode: newMode, timeLeft: newTimeLeft }));
        });

        timer.current.start({
          countdown: true,
          startValues: { seconds: timeLeft },
        });

        return () => {
            timer.current.stop();
            timer.current.removeEventListener('secondsUpdated');
            timer.current.removeEventListener('targetAchieved');
        };
    }, [dispatch, timeLeft, mode, cycles, timerConfig]);

    useEffect(() => {
        if (isActive) {
            if (timer.current.isRunning()) {
                timer.current.pause();
            }
            timer.current.start({ countdown: true, startValues: { seconds: timeLeft }, precision: 'seconds' });
        } else {
            timer.current.pause();
        }
    }, [isActive, timeLeft]);

    const handleReset = () => {
        let newTimeLeft = null;

        if (mode === 'work') {
          newTimeLeft = timerConfig === '25/5/15' ? 1500 : 3000;
        }
        else if (mode === 'shortBreak') {
          newTimeLeft = timerConfig === '25/5/15' ? 300 : 600;
        }

        else {
          newTimeLeft = timerConfig === '25/5/15' ? 900 : 1800;
        }

        dispatch(resetTimer(newTimeLeft));
        timer.current.reset();
    };

    const requestNotificationPermission = () => {
      if (Notification.permission === 'granted') {
        return;
      }
    
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
        } else {
          console.log('Notification permission denied.');
        }
      });
    };

    useEffect(() => {
      requestNotificationPermission();
    }, []);

    const handleModeChange = (newMode, newTimeLeft) => {
      dispatch(switchMode({ mode: newMode, timeLeft: newTimeLeft })); // Assuming you have an action to set mode
      dispatch(toggleTimer(false)); // Optional: Automatically pause on mode change

    };

    const handleSkip = () => {
      let newMode = '';
      let newTimeLeft = null;

      if (timerConfig === '25/5/15') {
        if (mode === 'work') {
          if (cycles === 3) {
            dispatch(resetCycles());
            newMode = 'longBreak';
            newTimeLeft = 900;
          } else {
            dispatch(addCycle());
            newMode = 'shortBreak';
            newTimeLeft = 300; // 5 minutes for short break
          }
        } else {
          newMode = 'work';
          newTimeLeft = 1500;
        }
      } else if (timerConfig === '50/10/30') {
          if (mode === 'work') {
            if (cycles === 3) {
              dispatch(resetCycles());
              newMode = 'longBreak';
              newTimeLeft = 1800;
            } else {
              dispatch(addCycle());
              newMode = 'shortBreak';
              newTimeLeft = 600;
            }
          } else {
            newMode = 'work';
            newTimeLeft = 3000;
          }
      }

      dispatch(switchMode({ mode: newMode, timeLeft: newTimeLeft }));
      dispatch(toggleTimer(false));
    };
  
  const handleTimerConfigChange = (config) => {
    dispatch(setTimerConfig(config));

    let newTimeLeft = null;
    if (mode === 'work') {
      newTimeLeft = config === '25/5/15' ? 1500 : 3000;
    } else if (mode === 'shortBreak') {
      newTimeLeft = config === '25/5/15' ? 300 : 600;
    } else {
      newTimeLeft = config === '25/5/15' ? 900 : 1800;
    }

    dispatch(resetTimer(newTimeLeft));
    timer.current.stop();  // Stop the existing timer
    timer.current.start({ countdown: true, startValues: { seconds: newTimeLeft } });

  };

  return (
    <div className={`pomodoro-panel ${mode}-panel`}>
        <div className="pomodoro-nav top-nav">
            <button className={`pomodoro-button ${mode === 'work' ? 'work-active' : ''} work-hover`} onClick={() => handleModeChange('work', timerConfig === '25/5/15' ? 1500 : 3000)}>Work</button>
            <button className={`pomodoro-button ${mode === 'shortBreak' ? 'short-break-active' : ''} short-break-hover`} onClick={() => handleModeChange('shortBreak', timerConfig === '25/5/15' ? 300 : 600)}>Short Break</button>
            <button className={`pomodoro-button ${mode === 'longBreak' ? 'long-break-active' : ''} long-break-hover`} onClick={() => handleModeChange('longBreak', timerConfig === '25/5/15' ? 900 : 1800)}>Long Break</button>
        </div>
        <div className="pomodoro-timer">
          <button className="reset-pomodoro" onClick={() => handleReset()}>
            <img src={resetImage} alt="Reset" width="40"></img>
          </button>
          {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
          <button className="next-pomodoro" onClick={() => handleSkip()}>
            <img src={nextImage} alt="Next" width="40"></img>
          </button>
        </div>
        <div className="pomodoro-nav bottom-nav">
          <label class="switcher inline-flex items-center cursor-pointer">
            <input type="checkbox" value='' className="sr-only peer" checked={timerConfig === '50/10/30'} onChange={() => handleTimerConfigChange(timerConfig === '25/5/15' ? '50/10/30' : '25/5/15')}></input>
            <div class="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full  after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-black"></div>
            <span class="ms-3 text-sm font-medium text-black dark:text-black fs-5">Extended</span>
          </label>
          <button className="pomodoro-button start-button" onClick={() => dispatch(toggleTimer(!isActive))}>
            {isActive ? 'PAUSE' : 'START'}
          </button>
          <div className="cycles">Cycles</div>
        </div>
    </div>
  );
}

export default Pomodoro;