import { configureStore } from '@reduxjs/toolkit';
import timerReducer from './slices/timerSlice';
import passwordReducer from './slices/passwordSlice';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';

const persistConfig = {
  key: 'root', // Key used for the persisted state
  storage, // Storage method (in this case, localStorage),
  whitelist: ['password']
};

const rootReducer = combineReducers({
  timer: timerReducer,
  password: passwordReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for easytimer.js
    }),
});

export const persistor = persistStore(store);