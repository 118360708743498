import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Analytics } from "@vercel/analytics/react";
import Home from './pages/Home';
import Taxi from './pages/taxi/Taxi';
import Login from './components/login/Login';

function App() {
  const isAuthenticatedRoot = useSelector(state => state.password.isAuthenticatedRoot);
  const isAuthenticatedTaxi = useSelector(state => state.password.isAuthenticatedTaxi);

  return (
    <Router>
      <Routes>
        <Route path="/login-root" element={isAuthenticatedRoot ? <Navigate to="/" /> : <Login page="root" />} />
        <Route path="/login-taxi" element={isAuthenticatedTaxi ? <Navigate to="/taxi" /> : <Login page="taxi" />} />
        <Route path="/" element={isAuthenticatedRoot ? <Home /> : <Navigate to="/login-root" />} />
        <Route path="/taxi" element={isAuthenticatedTaxi ? <Taxi /> : <Navigate to="/login-taxi" />} />
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;