import React from 'react';
import './Taxi.css';

function Taxi() {
  const handleTransfer = () => {
    window.open('https://3.redirect.appmetrica.yandex.com/route?tariffClass=econom&ref=7806894&appmetrica_tracking_id=1178268795219780156&lang=ru&data-app=3', '_blank');
  };

  return (
    <div className="taxi-container">
      <button className="transfer-button" onClick={handleTransfer}>
        Сделать заказ
      </button>
    </div>
  );
}

export default Taxi;