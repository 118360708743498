import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isActive: false,
  timeLeft: 1500,  // Initial time set to 25 minutes
  mode: 'work',    // 'work', 'shortBreak', 'longBreak'
  cycles: 0,
  timerConfig: '25/5/15'
};

const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    toggleTimer(state, action) {
      state.isActive = action.payload;
    },
    resetTimer(state, action) {
      state.isActive = false;
      state.timeLeft = action.payload; 
    },
    updateTimeLeft(state, action) {
      state.timeLeft = action.payload;
    },
    switchMode(state, action) {
      state.mode = action.payload.mode;
      state.timeLeft = action.payload.timeLeft;
    },
    addCycle(state) {
      state.cycles++;
    },
    resetCycles(state) {
      state.cycles = 0;
    },
    setTimerConfig(state, action) {
      state.timerConfig = action.payload;
    }
  }
});

export const { toggleTimer, resetTimer, updateTimeLeft, switchMode, addCycle, resetCycles, setTimerConfig } = timerSlice.actions;
export default timerSlice.reducer;