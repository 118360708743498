import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const passwordSlice = createSlice({
  name: 'password',
  initialState: {
    isAuthenticatedRoot: false,
    isAuthenticatedTaxi: false
  },
  reducers: {
    authenticateRoot: (state) => {
      state.isAuthenticatedRoot = true;
    },
    authenticateTaxi: (state) => {
      state.isAuthenticatedTaxi = true;
    },
    logoutRoot: (state) => {
      state.isAuthenticatedRoot = false;
    },
    logoutTaxi: (state) => {
      state.isAuthenticatedTaxi = false;
    }
  }
});

export const { authenticateRoot, authenticateTaxi, logoutRoot, logoutTaxi } = passwordSlice.actions;

const persistConfig = {
  key: 'password',
  storage,
};

const persistedReducer = persistReducer(persistConfig, passwordSlice.reducer);

export default persistedReducer;